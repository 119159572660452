
import { computed, defineComponent, ref, onMounted, watch } from "vue";
import arraySort from "array-sort";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "datatable",
  props: {
    tableHeader: { type: Array, required: true },
    tableData: { type: Array, required: true },
    enableItemsPerPageDropdown: Boolean,
    rowsPerPage: Number,
    rowClass: String,
  },
  components: {},
  setup(props, { emit }) {
    const data = computed(() => props.tableData);
    const currentSort = ref<string>("");
    const click = ref<number>(1);
    const pagination = ref<IPagination>({
      page: 1,
      total: 0,
      rowsPerPage: 10,
    });

    const emitRowClick = (item) => {
      emit("rowClicked", item);
    };

    watch(data.value, () => {
      pagination.value.total = data.value.length;
    });
    onMounted(() => {
      pagination.value.rowsPerPage = props.rowsPerPage ? props.rowsPerPage : 10;
      pagination.value.total = data.value.length;
    });

    const getItems = computed(() => {
      const clone = JSON.parse(JSON.stringify(data.value));
      const startFrom =
        pagination.value.page * pagination.value.rowsPerPage -
        pagination.value.rowsPerPage;
      return clone.splice(startFrom, pagination.value.rowsPerPage);
    });
    const pages = computed(() => {
      return Math.ceil(pagination.value.total / pagination.value.rowsPerPage);
    });
    const setCurrent = (val) => {
      pagination.value.page = val;
    };
    const sort = (columnName, sortable) => {
      if (!sortable) {
        return;
      }

      if (click.value === 2) {
        click.value = 1;
        arraySort(data.value, columnName, { reverse: false });
      } else {
        click.value++;
        arraySort(data.value, columnName, { reverse: true });
      }
      currentSort.value = columnName + click.value;
    };

    return {
      pagination,
      pages,
      setCurrent,
      getItems,
      sort,
      currentSort,
      emitRowClick,
    };
  },
});
