
import { defineComponent, ref, onMounted, computed, onBeforeMount } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";
import dayjs from "dayjs";

export default defineComponent({
  name: "notifications-list",
  components: {
    Datatable,
  },
  methods: {
    formatDate(date: Date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm");
    },
  },
  setup() {
    const store = useStore();
    const headerConfig = ref([
      {
        name: "Phone number",
        key: "sentTo",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Scheduled Date",
        key: "scheduledDate",
        sortable: true,
      },
      {
        name: "Job Date Creation",
        key: "dateToSend",
        sortable: true,
      },
    ]);
    const search = ref<string>("");
    const smsNotifications = computed(
      () => store.state.SMSNotificationModule.smsNotifications
    );

    const displayedSMSNotifications = computed(() =>
      smsNotifications.value.filter((smsNotification) => {
        return (
          smsNotification.sentTo &&
          smsNotification.sentTo
            .toString()
            .toLowerCase()
            .includes(search.value.toLowerCase())
        );
      })
    );

    onBeforeMount(() => {
      store.dispatch(Actions.GET_SMS_NOTIFICATIONS);
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("SMS Notification List", [
        "Apps",
        "SMS Notifications",
      ]);
    });

    return {
      headerConfig,
      displayedSMSNotifications,
      search,
    };
  },
  get smsNotifications() {
    return this.smsNotifications;
  },
});
